/* eslint-disable quotes */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from "react";

import Seo from "../components/Seo";
import EpisodesLayout from "../components/EpisodesLayout";
import PopularSection from "../components/PopularSection";

const PopularPage = () => (
  <div className="bg-background">
    <div className="mx-auto max-w-[85.375rem] bg-background shadow-xl shadow-[#000000ad]">
      <EpisodesLayout>
        <Seo title="Most popular" />
        <PopularSection />
      </EpisodesLayout>
    </div>
  </div>
);

export default PopularPage;
